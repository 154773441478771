import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { FirstNameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/FirstNameItem/FirstNameItem';
import { LastNameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/LastNameItem/LastNameItem';
import { NicknameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/NicknameItem/NicknameItem';
import { SexItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/SexItem/SexItem';
import { BirthdayItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/BirthdayItem/BirthdayItem';
import { LanguageItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/LanguageItem/LanguageItem';
import { PhoneItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/PhoneItem/PhoneItem';
import { EmailItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/EmailItem/EmailItem';
import { CountriesItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/CountriesItem/CountriesItem';
import { CitiesItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/CitiesItem/CitiesItem';
import { ZipcodeItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/ZipcodeItem/ZipcodeItem';
import { AddressItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/AddressItem/AddressItem';
import { WebsiteItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/WebsiteItem/WebsiteItem';
import { SocialLinksItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/SocialLinksItem/SocialLinksItem';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Dates } from '@app/constants/Dates';
import { notificationController } from '@app/controllers/notificationController';
import { PaymentCard } from '@app/interfaces/interfaces';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { UserLoginData } from '@app/types/ResponseType';
import { BaseButton } from './common/BaseButton/BaseButton';
import { BaseForm } from './common/forms/BaseForm/BaseForm';
import { BaseInput } from './common/inputs/BaseInput/BaseInput';
import { ClipboardInput } from './common/inputs/ClipboardInput/ClipboardInput';
import { checkExistingApiKey, createApiKey, deleteApiKey } from '@app/api/user.api';

const initialPersonalInfoValues: any = {
  firstName: '',
  lastName: '',
  phoneNumber: null,
  email: '',
};

export const CreateApi: React.FC = () => {
  const [isApiKeyExists, setIsApiKeyExists] = useState(false);
  const [clipboardValue, setClipboardValue] = useState('');
  const [createdApikeyNow, setCreatedApikeyNow] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    // if (!isApiKeyExists) {
    setLoading(true);

    checkExistingApiKey()
      .then((existingApiKeyStatus) => {
        setLoading(false);

        console.log('useEffect executes');
        if (existingApiKeyStatus?.status === 200) {
          if (existingApiKeyStatus.data.responseData[0]) {
            existingApiKeyStatus.data.responseData[0].exist && !createdApikeyNow
              ? setClipboardValue('*****************************************************')
              : '';
            setIsApiKeyExists(existingApiKeyStatus.data.responseData[0].exist);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);

        notificationController.error({ message: 'Something went wrong' });
      });
    // }
  }, [clipboardValue]);
  const user = useAppSelector((state) => state.user.user);

  const [isFieldsChanged, setFieldsChanged] = useState(false);

  const userFormValues = useMemo(
    () =>
      user
        ? {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user?.phoneNumber,
          }
        : initialPersonalInfoValues,
    [user],
  );

  const [form] = BaseButtonsForm.useForm();

  const { t } = useTranslation();

  const onFinish = useCallback(
    (values: PaymentCard) => {
      // todo dispatch an action here
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setFieldsChanged(false);
        notificationController.success({ message: t('common.success') });
        console.log(values);
      }, 1000);
    },
    [t],
  );

  const createApiHandler = () => {
    setLoading(true);

    createApiKey()
      .then((createApiKeyResponse) => {
        setLoading(false);

        if (createApiKeyResponse?.status === 200) {
          setClipboardValue(createApiKeyResponse.data.responseData[0].apiKey);
          notificationController.success({ message: 'Generated API Key Successfully' });
          setCreatedApikeyNow(true);
        }
      })
      .catch((error) => {
        setLoading(false);

        console.error(error);
        notificationController.error({ message: 'Something went wrong' });
      });
  };

  const deleteApiHandler = () => {
    setLoading(true);

    deleteApiKey()
      .then((deleteApiKeyResponse) => {
        setLoading(false);

        if (deleteApiKeyResponse?.status === 200) {
          setClipboardValue('');
          notificationController.success({ message: 'Revoked API Key Successfully' });
        }
      })
      .catch((error) => {
        setLoading(false);

        console.error(error);
        notificationController.error({ message: 'Something went wrong' });
      });
  };

  return (
    <BaseCard>
      <BaseButtonsForm
        form={form}
        name="info"
        loading={isLoading}
        initialValues={userFormValues}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
        onFieldsChange={() => setFieldsChanged(true)}
        onFinish={onFinish}
      >
        <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
          <BaseCol span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>{'API Keys'}</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
          </BaseCol>

          {createdApikeyNow && (
            <BaseCol xs={24} md={12}>
              <ClipboardInput
                disabled={isApiKeyExists}
                value={clipboardValue}
                placeholder={'API Key'}
                valueToCopy={clipboardValue}
                onChange={(e) => setClipboardValue(e.target.value)}
              />
            </BaseCol>
          )}

          {isApiKeyExists && !createdApikeyNow && (
            <BaseCol xs={24} md={12}>
              {/* <ClipboardInput
                disabled={isApiKeyExists}
                value={clipboardValue}
                placeholder={'API Key'}
                valueToCopy={clipboardValue}
                onChange={(e) => setClipboardValue(e.target.value)}
              /> */}

              <BaseInput disabled={isApiKeyExists} value={clipboardValue} placeholder={'API Key'}></BaseInput>
            </BaseCol>
          )}

          {!isApiKeyExists && (
            <BaseCol xs={12} md={6}>
              <BaseButton type="primary" onClick={createApiHandler} loading={isLoading}>
                {'Create API'}
              </BaseButton>
            </BaseCol>
          )}

          {isApiKeyExists && (
            <BaseCol xs={12} md={6}>
              <BaseButton severity="error" onClick={deleteApiHandler} loading={isLoading}>
                {'Revoke'}
              </BaseButton>
            </BaseCol>
          )}

          <BaseCol xs={24}>
            <div style={{ marginTop: '1rem' }}>
              <div style={{ fontWeight: 600 }}> Note : </div>
              <div>
                API key generated cannot be copied after you left this screen. Be sure to save it in a secure location.
                If you have any security concerns, you can always revoke access and create a new API Key.
              </div>
            </div>
          </BaseCol>
        </BaseRow>
      </BaseButtonsForm>
    </BaseCard>
  );
};
