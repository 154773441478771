import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Pagination, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { Movie, Song, TopSelling } from '@app/types/ResponseType';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};
interface TopSongsTable {
  topSellingData: TopSelling[];
}

export const TopSongsTable: React.FC<TopSongsTable> = (props: TopSongsTable) => {
  const [tableData, setTableData] = useState<{ data: TopSelling[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      if (isMounted.current) {
        setTableData({
          data: props.topSellingData,
          pagination: { current: 1, pageSize: 5, total: 5 },
          loading: false,
        });
      }
    },
    [isMounted, props?.topSellingData],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  // const handleDeleteRow = (rowId: number) => {
  //   setTableData({
  //     ...tableData,
  //     data: tableData.data.filter((item) => item.key !== rowId),
  //     pagination: {
  //       ...tableData.pagination,
  //       total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
  //     },
  //   });
  // };

  const columns: ColumnsType<Song> = [
    {
      title: 'Title',
      dataIndex: 'title',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Artists',
      dataIndex: 'artist',
      render: (text: string[]) => <span>{text}</span>,
      width: 70,
    },
    {
      title: 'Movie',
      dataIndex: 'moviename',
    },
  ];

  return (
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      loading={tableData.loading}
      onChange={handleTableChange}
      pagination={false}
      bordered
    />
  );
};
