import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
// import { UserModel } from '@app/domain/UserModel';
import { persistUserDemo, readUserDemo } from '@app/services/localStorage.service';
import { UserLoginData } from '@app/types/ResponseType';

export interface UserState {
  user: UserLoginData | null;
}

const initialState: UserState = {
  user: readUserDemo(),
};

export const setUser = createAction<PrepareAction<UserLoginData>>('user/setUser', (newUser) => {
  // persistUserDemo(newUser);
  return {
    payload: newUser,
  };
});
export const setUserData = createAction<PrepareAction<UserLoginData>>('user/setUser', (newUser) => {
  persistUserDemo(newUser);

  return {
    payload: newUser,
  };
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
  },
});

export default userSlice.reducer;
