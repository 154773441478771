import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Pagination, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { Movie, TopSelling } from '@app/types/ResponseType';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};
interface TopMoviesTableProps {
  topSellingData: TopSelling[];
}

export const TopMoviesTable: React.FC<TopMoviesTableProps> = (props: TopMoviesTableProps) => {
  const [tableData, setTableData] = useState<{ data: TopSelling[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      if (isMounted.current) {
        setTableData({ data: props.topSellingData, pagination: { current: 1, pageSize: 5, total: 5 }, loading: false });
      }
    },
    [isMounted, props?.topSellingData],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  // const handleDeleteRow = (rowId: number) => {
  //   setTableData({
  //     ...tableData,
  //     data: tableData.data.filter((item) => item.key !== rowId),
  //     pagination: {
  //       ...tableData.pagination,
  //       total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
  //     },
  //   });
  // };

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: 'Movie',
      dataIndex: 'moviename',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Cast',
      dataIndex: 'cast',
      render: (text: string[]) => <span>{text.join(', ')}</span>,
    },
    {
      title: 'Language',
      dataIndex: 'language',
      render: (text: string[]) => <span>{text}</span>,
    },
  ];

  return (
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      pagination={false}
      loading={tableData.loading}
      onChange={handleTableChange}
      bordered
    />
  );
};
