import React, { useEffect, useRef, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { References } from '@app/components/common/References/References';
import { useResponsive } from '@app/hooks/useResponsive';
import { TrendingCreators } from '@app/components/nft-dashboard/trending-creators/TrendingCreators';
import { RecentlyAddedNft } from '@app/components/nft-dashboard/recently-added/RecentlyAddedNft';
import { TrendingCollections } from '@app/components/nft-dashboard/trending-collections/TrendingCollections';
// import { Balance } from '@app/components/nft-dashboard/Balance/Balance';
// import { TotalEarning } from '@app/components/nft-dashboard/totalEarning/TotalEarning';
import { ActivityStory } from '@app/components/nft-dashboard/activityStory/ActivityStory';
import { RecentActivity } from '@app/components/nft-dashboard/recentActivity/RecentActivity';
import * as S from './DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { StatisticsCards } from '@app/components/medical-dashboard/statisticsCards/StatisticsCards';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { Title } from '@app/components/common/BaseArticle/BaseArticle.styles';
import { PlayCircleOutlined } from '@ant-design/icons';
import { SalesBarChart } from '@app/components/charts/SalesBarChart/SalesBarChart';
import { VisitorsPieChart } from '@app/components/charts/VisitorsPieChart';
import { BasicTable } from '@app/components/tables/BasicTable/BasicTable';
import { TopMoviesTable } from '@app/components/tables/TopMoviesTable/TopMoviesTable';
import { SalesTable } from '@app/components/tables/SalesTable/SalesTable';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { BaseDivider } from '@app/components/common/BaseDivider/BaseDivider';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { TopSongsTable } from '@app/components/tables/TopSongsTable/TopSongsTable';
import { getContentOwnerSales, getDashBoardData, getTopSellingSongs } from '@app/api/user.api';
import { setSales } from '@app/store/slices/saleSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import moment, { Moment } from 'moment';
import { DashboardRangeData, Stats, TopSelling } from '@app/types/ResponseType';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const MedicalDashboardPage: React.FC = () => {
  const dispatch = useAppDispatch();
  // const salesListState = useAppSelector((state) => state.sales.sales.sales);

  const [dashboardRangeData, setDashboardRangeData] = useState<DashboardRangeData>({ sales: [], stats: {} });
  const [topSelling, setTopSelling] = useState<TopSelling[]>();
  const [dashboardData, setDashboardData] = useState<Stats>({
    movies: 0,
    media: 0,
    products: 0,
    reels: 0,
  });

  useEffect(() => {
    getContentOwnerSales({
      startDate: '2023-01-30',
      endDate: '2023-10-20',
    })
      .then((salesResponse) => {
        if (salesResponse?.status === 200) {
          setDashboardRangeData(salesResponse.data.responseData);
          // dispatch(setSales(salesResponse.data.responseData));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    getDashBoardData()
      .then((dashboardResponse) => {
        if (dashboardResponse?.status === 200) {
          console.log(dashboardResponse);
          setDashboardData(dashboardResponse.data.responseData?.stats);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Top selling Songs
  useEffect(() => {
    getTopSellingSongs()
      .then((topSellingResponse) => {
        if (topSellingResponse?.status === 200) {
          console.log(topSellingResponse);
          setTopSelling(topSellingResponse.data.responseData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const dateFormat = 'YYYY-MM-DD';

  const [selectedRange, setSelectedRange] = useState<any>(null);
  const submitSalesRange = () => {
    console.log('Submit clicked');
    console.log(selectedRange);
    getContentOwnerSales({
      startDate: selectedRange[0].format('YYYY-MM-DD'),
      endDate: selectedRange[1].format('YYYY-MM-DD'),
    })
      .then((salesResponse) => {
        if (salesResponse?.status === 200) {
          // dispatch(setSales(salesResponse.data.responseData));
          setDashboardRangeData(salesResponse.data.responseData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  let totalPrices;
  let saleDates;
  const sumBySaleDate: any = {};

  if (!_.isEmpty(dashboardRangeData?.sales)) {
    dashboardRangeData.sales.forEach((sale) => {
      const saleDate = sale.saleDate;
      const totalPrice = parseFloat(sale.totalPrice);

      // Check if the saleDate already exists in the sumBySaleDate object
      if (sumBySaleDate[saleDate]) {
        sumBySaleDate[saleDate] += totalPrice;
      } else {
        sumBySaleDate[saleDate] = totalPrice;
      }
    });

    // Extract unique saleDates and their corresponding summed total prices
    saleDates = Object.keys(sumBySaleDate);
    saleDates.sort((a: any, b: any) => moment(b).diff(moment(a)));

    totalPrices = saleDates.map((date) => sumBySaleDate[date]);
  }
  console.log('totalPrices', totalPrices);
  console.log('saleDates', saleDates);

  const { isDesktop } = useResponsive();

  let totalPriceOfProductSold = 0;
  if (dashboardRangeData?.sales.length > 0) {
    dashboardRangeData.sales.map((sale) => {
      totalPriceOfProductSold = totalPriceOfProductSold + parseFloat(sale.totalPrice);
    });
  }

  const input = document.getElementById('elementToBePDf'); // 'pdf-content' is the ID of your React component's container div

  const downloadPdfWIthHtmlCanvas = () => {
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 10, 10, 190, 0);
        pdf.save('report.pdf');
      });
    }
  };
  const desktopLayout = (
    <div id="elementToBePDf">
      <BaseRow>
        <S.LeftSideCol id="desktop-content">
          <BaseRow gutter={[60, 60]}>
            {/* DASHBOARD STATS */}
            <BaseCol span={24}>
              <h2 style={{ marginBottom: '1rem', fontWeight: 600 }}>Dashboard</h2>
              <BaseRow gutter={[30, 30]} justify={'space-between'}>
                <S.CardHeading padding="1.25rem">
                  <div style={{ margin: 0 }}>Movies</div>
                  <h2 style={{ textAlign: 'center' }}>{dashboardData?.movies}</h2>
                </S.CardHeading>
                <S.CardHeading padding="1.25rem">
                  <div style={{ margin: 0 }}>Songs</div>
                  <h2 style={{ textAlign: 'center' }}>{dashboardData?.media}</h2>
                </S.CardHeading>
                <S.CardHeading padding="1.25rem">
                  <div style={{ margin: 0 }}>Reels</div>
                  <h2 style={{ textAlign: 'center' }}>{dashboardData?.reels}</h2>
                </S.CardHeading>
                <S.CardHeading padding="1.25rem">
                  <div style={{ margin: 0 }}>Merchandise</div>
                  <h2 style={{ textAlign: 'center' }}>{dashboardData?.products}</h2>
                </S.CardHeading>
              </BaseRow>
            </BaseCol>

            {/* SALES SECTION */}
            <BaseCol id="sales-bar-chart" span={16}>
              {/* SALES CHARTS */}
              <SalesBarChart data={totalPrices || []} labels={saleDates || []} />
            </BaseCol>
            <BaseCol span={8}>
              <BaseRow gutter={[10, 10]} style={{ marginTop: '1rem', fontSize: '1.2rem' }}>
                {/* <BaseButton
                  type="ghost"
                  loading={false}
                  onClick={() => {
                    downloadPdfWIthHtmlCanvas();
                  }}
                >
                  {'Export PDF'}
                </BaseButton> */}
                <BaseCol span={24}>
                  <BaseForm.Item name="datePicker" label={'Date Range'}>
                    <DayjsDatePicker.RangePicker
                      // format="L"
                      format={dateFormat}
                      onChange={(values) => {
                        setSelectedRange(values);
                        console.log(values);
                      }}
                      // defaultValue={[moment('2015/01/01', dateFormat), moment('2015/01/01', 'YYYY-MM-DD')]}
                    />
                  </BaseForm.Item>
                  <BaseButton type="ghost" loading={false} onClick={submitSalesRange}>
                    {'Submit'}
                  </BaseButton>
                </BaseCol>
                <BaseDivider />

                <BaseCol span={15}>
                  <S.ChartStatsHeading>Products Mapped</S.ChartStatsHeading>{' '}
                </BaseCol>
                <BaseCol span={9}>{dashboardRangeData?.stats?.productsMapped}</BaseCol>
                <BaseCol span={15}>
                  <S.ChartStatsHeading>Products Sold</S.ChartStatsHeading>
                </BaseCol>
                <BaseCol span={9}>{dashboardRangeData.sales?.length}</BaseCol>
                <BaseCol span={15}>
                  <S.ChartStatsHeading>Products Sold Amount</S.ChartStatsHeading>
                </BaseCol>
                <BaseCol span={9}>{totalPriceOfProductSold ? '₹' + totalPriceOfProductSold : '-'}</BaseCol>
              </BaseRow>
            </BaseCol>

            <BaseCol>
              <S.Card id="sales-table" title={'Sales Data'}>
                <SalesTable saleList={dashboardRangeData.sales || []} />
              </S.Card>
            </BaseCol>

            {/* <BaseCol id="pie" xs={24} lg={12}>
            <VisitorsPieChart />
          </BaseCol> */}

            <BaseCol span={12}>
              <S.Card id="top-movies-table" title={'Top Selling Movies'} padding="1.25rem 1.25rem 0">
                <TopMoviesTable topSellingData={topSelling || []} />
              </S.Card>
            </BaseCol>
            <BaseCol span={12}>
              <S.Card id="top-songs-table" title={'Top Selling Songs'} padding="1.25rem 1.25rem 0">
                <TopSongsTable topSellingData={topSelling || []} />
              </S.Card>
            </BaseCol>
          </BaseRow>
          {/* <References /> */}
        </S.LeftSideCol>

        {/* <S.RightSideCol xl={8} xxl={7}>
        <div id="balance">
          <Balance />
        </div>
        <S.Space />
        <div id="total-earning">
          <TotalEarning />
        </div>
        <S.Space />
        <S.ScrollWrapper id="activity-story">
          <ActivityStory />
        </S.ScrollWrapper>
      </S.RightSideCol> */}
      </BaseRow>
    </div>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <TrendingCreators />
      </BaseCol>

      <BaseCol span={24}>
        <RecentlyAddedNft />
      </BaseCol>

      <BaseCol span={24}>
        <TrendingCollections />
      </BaseCol>

      <BaseCol span={24}>
        <RecentActivity />
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>NFT Dashboard</PageTitle>
      {isDesktop ? desktopLayout : desktopLayout}
    </>
  );
};

export default MedicalDashboardPage;
